import moment from 'moment';

interface PartialDate {
  day?: string;
  month?: string;
  year?: string;
}

function monthToNumber(month?: string): string {
  if (!month) return '01';
  const monthIndex = moment().month(month).format('M');
  return monthIndex.padStart(2, '0');
}

export function isBirthDateBeforeDeathDate(
  birthDate?: PartialDate,
  deathDate?: PartialDate
): boolean {
  // Check if either date is undefined or if years are invalid
  if (
    !birthDate ||
    !deathDate ||
    birthDate.year?.length !== 4 ||
    deathDate.year?.length !== 4
  ) {
    return true;
  }

  const birthYear = birthDate.year;
  const birthMonth = monthToNumber(birthDate.month);
  const birthDay = birthDate.day;

  const deathYear = deathDate.year;
  const deathMonth = monthToNumber(deathDate.month);
  const deathDay = deathDate.day;

  let birthMoment: moment.Moment;
  let deathMoment: moment.Moment;

  if (birthDate.day && deathDate.day) {
    birthMoment = moment(
      `${birthYear}-${birthMonth}-${birthDay}`,
      'YYYY-MM-DD'
    );
    deathMoment = moment(
      `${deathYear}-${deathMonth}-${deathDay}`,
      'YYYY-MM-DD'
    );
  } else if (birthDate.month && deathDate.month) {
    birthMoment = moment(`${birthYear}-${birthMonth}`, 'YYYY-MM');
    deathMoment = moment(`${deathYear}-${deathMonth}`, 'YYYY-MM');
  } else {
    birthMoment = moment(`${birthYear}`, 'YYYY');
    deathMoment = moment(`${deathYear}`, 'YYYY');
  }

  return birthMoment.isSameOrBefore(deathMoment);
}
