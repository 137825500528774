import type { IconProps } from '../types/icons';

export default function EditInfoIcon({
  width = 18,
  height = 18,
  fill = '#9FA3A9',
  className = '',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        d="M2 0C0.89 0 0 0.89 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V9H16V16H2V2H9V0H2ZM14.78 1C14.61 1 14.43 1.07 14.3 1.2L13.08 2.41L15.58 4.91L16.8 3.7C17.06 3.44 17.06 3 16.8 2.75L15.25 1.2C15.12 1.07 14.95 1 14.78 1ZM12.37 3.12L5 10.5V13H7.5L14.87 5.62L12.37 3.12Z"
        fill={fill}
      />
    </svg>
  );
}
