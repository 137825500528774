import { useRouter } from 'next/router';
import { setCookie } from 'src/utils/cookies';
import { PrimaryButton } from '../primary-button/primary-button';

type NotLoggedInProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectCreateEditOption: any;
  showReviewView: boolean;
};

export default function NotLoggedInBanner({
  selectCreateEditOption,
  showReviewView,
}: NotLoggedInProps) {
  const router = useRouter();

  const handleLoginRedirect = (
    e:
      | React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
      | undefined
  ) => {
    e && e.preventDefault();
    setCookie('redirectUrl', router.asPath);
    router.push('/sign-in');
    return;
  };

  return (
    <div className="bg-[#D9ECE7] px-4">
      <div
        className={`mx-auto flex max-w-[1480px] items-center justify-between px-4 py-2 pt-4`}
      >
        <div className="flex max-w-5xl">
          <svg
            className="mr-[20px] mt-[-2px]"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6875 15.125V9.625H8.9375V11H10.3125V15.125H8.25V16.5H13.75V15.125H11.6875ZM11 5.5C10.796 5.5 10.5967 5.56048 10.4271 5.6738C10.2575 5.78711 10.1253 5.94817 10.0472 6.13661C9.9692 6.32504 9.94877 6.53239 9.98857 6.73244C10.0284 6.93248 10.1266 7.11623 10.2708 7.26045C10.415 7.40468 10.5988 7.50289 10.7988 7.54269C10.9989 7.58248 11.2062 7.56205 11.3946 7.484C11.5831 7.40595 11.7441 7.27377 11.8575 7.10418C11.9708 6.93459 12.0312 6.73521 12.0312 6.53125C12.0312 6.25775 11.9226 5.99544 11.7292 5.80205C11.5358 5.60865 11.2735 5.5 11 5.5Z"
              fill="#409F85"
            />
            <path
              d="M11 20.625C9.09636 20.625 7.23546 20.0605 5.65264 19.0029C4.06982 17.9453 2.83616 16.4421 2.10766 14.6833C1.37917 12.9246 1.18856 10.9893 1.55995 9.12226C1.93133 7.25519 2.84802 5.54018 4.1941 4.1941C5.54018 2.84802 7.25519 1.93133 9.12226 1.55995C10.9893 1.18856 12.9246 1.37917 14.6833 2.10766C16.4421 2.83616 17.9453 4.06982 19.0029 5.65264C20.0605 7.23546 20.625 9.09636 20.625 11C20.625 13.5527 19.6109 16.0009 17.8059 17.8059C16.0009 19.6109 13.5527 20.625 11 20.625ZM11 2.75C9.36831 2.75 7.77326 3.23386 6.41655 4.14038C5.05984 5.0469 4.00242 6.33538 3.378 7.84287C2.75358 9.35036 2.5902 11.0092 2.90853 12.6095C3.22685 14.2098 4.01259 15.6799 5.16637 16.8336C6.32016 17.9874 7.79017 18.7732 9.39051 19.0915C10.9909 19.4098 12.6497 19.2464 14.1571 18.622C15.6646 17.9976 16.9531 16.9402 17.8596 15.5835C18.7662 14.2268 19.25 12.6317 19.25 11C19.25 8.81197 18.3808 6.71355 16.8336 5.16637C15.2865 3.6192 13.188 2.75 11 2.75Z"
              fill="#409F85"
            />
          </svg>{' '}
          <p
            className={`text-[14px] font-[500] leading-[135.4%] max-xl:mb-[10px]`}
          >
            You’re not logged in. If you edit or create records, we’ll record
            your IP address.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={selectCreateEditOption}
            className={`py-[8px] xl:py-[12px]`}
          >
            <p className={`text-[12px] text-[#409F85] xl:text-base`}>DISMISS</p>
          </button>
          <PrimaryButton
            href="/sign-in"
            text="LOGIN"
            textClassName="text-sm"
            onClick={handleLoginRedirect}
            extraButtonClassName="w-fit md:ml-auto p-3 mb-2 rounded-lg shadow-none"
          />
        </div>
      </div>
    </div>
  );
}
