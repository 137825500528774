import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';

import { BiMinus } from 'react-icons/bi';
import PlusIcon from 'src/assets/PlusIcon';
import QuestionMarkIconCircle from 'src/assets/QuestionMarkIconCircle';
import type { DataWorldChartTypes } from 'src/types/types';
import { nameRegex, optionalNameRegex } from 'src/utils/format';
import TailwindAlert from '../alert/tailwind-alert';
import ReactTooltip from '../react-tooltip/ReactTooltip';
import { SecondaryButton } from '../secondary-button/secondary-button';
import TailwindSelect from '../select/select';

interface MainInformationProps {
  countries: DataWorldChartTypes[];
  handleFirstName: (value: string) => void;
  handleLastName: (value: string) => void;
  handleMiddleName: (value: string) => void;
  handleMaidenName: (value: string) => void;
  onSelectCountry: (country: DataWorldChartTypes | undefined) => void;
  handleIsMale: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleIsFemale: (e: React.ChangeEvent<HTMLInputElement>) => void;
  continueAndReview: boolean;
  firstName?: string;
  middleName?: string;
  maidenName?: string;
  countryRef?: string;
  lastName?: string;
  isFemale: boolean;
  isMale: boolean;
  isFromUpdatePage?: boolean;
}

export default function MainInformation({
  countries = [],
  handleFirstName,
  handleLastName,
  handleMiddleName,
  handleMaidenName,
  onSelectCountry,
  handleIsMale,
  handleIsFemale,
  continueAndReview,
  isFemale,
  middleName,
  maidenName,
  firstName,
  lastName,
  isMale,
  countryRef,
  isFromUpdatePage = false,
}: MainInformationProps) {
  const [showMiddleName, setShowMiddleName] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isHideMiddleNameForced, setIsHideMiddleNameForced] = useState(false);
  const [firstNameState, setFirstNameState] = useState({
    value: '',
    error: false,
    touched: false,
  });
  const [maidenNameState, setMaidenNameState] = useState({
    value: '',
    error: false,
    touched: false,
  });
  const [lastNameState, setLastNameState] = useState({
    value: '',
    error: false,
    touched: false,
  });

  useEffect(() => {
    !isHideMiddleNameForced &&
      setShowMiddleName(
        (isFemale || isMale) && !!countryRef && !!firstName && !!lastName
      );
    handleMiddleName('');
  }, [
    isFemale,
    isMale,
    countryRef,
    firstName,
    lastName,
    isHideMiddleNameForced,
  ]);

  const handleFirstNameState = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e?.target?.value;
    if (!nameRegex.test(inputValue)) {
      setFirstNameState({ value: e.target.value, error: true, touched: true });
    } else {
      setFirstNameState({ value: e.target.value, error: false, touched: true });
    }
    handleFirstName(e.target.value);
  };

  const handleMiddleNameState = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e?.target?.value;
    handleMiddleName(inputValue);
  };

  const handleMaidenNameState = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e?.target?.value;
    if (!optionalNameRegex.test(inputValue)) {
      setMaidenNameState({ value: e.target.value, error: true, touched: true });
    } else {
      setMaidenNameState({
        value: e.target.value,
        error: false,
        touched: true,
      });
    }
    handleMaidenName(inputValue);
  };

  const handleLastNameState = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e?.target?.value;
    if (!nameRegex.test(inputValue)) {
      setLastNameState({ value: inputValue, error: true, touched: true });
    } else {
      setLastNameState({ value: inputValue, error: false, touched: true });
    }
    handleLastName(inputValue);
  };

  useEffect(() => {
    if (onSelectCountry && selectedCountry) {
      const selectedCountryObject = countries.find(
        (country: DataWorldChartTypes) => country.name === selectedCountry
      );

      onSelectCountry(selectedCountryObject);
    }
  }, [selectedCountry]);

  const handleCountry = (country: string) => {
    setSelectedCountry(country);
  };

  useEffect(() => {
    if (middleName) {
      !isHideMiddleNameForced && setShowMiddleName(true);
    }
  }, [middleName]);

  const handleShowMiddleName = () => {
    setIsHideMiddleNameForced((prev) => !prev);

    !isHideMiddleNameForced && setShowMiddleName((prev) => !prev);
    if (showMiddleName) {
      handleMiddleName('');
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-lg font-semibold">
        Main Information <span className="text-red-500">*</span>
      </h4>
      <div className="text-slateBlack flex flex-col gap-4 text-sm">
        <div className="flex flex-col gap-2">
          <span className="flex items-center">
            Sex at birth <span className="text-red-500">*</span>{' '}
            <QuestionMarkIconCircle
              className="ml-2 flex size-5 items-center"
              id="sex-at-birth"
              data-for="sex-at-birth"
              data-tip={'The sex as it appears on the birth certificate.'}
            />
            <ReactTooltip
              className="tooltip-opacity"
              id="sex-at-birth"
              type={'info'}
              multiline={true}
              effect={'solid'}
              place="top"
              backgroundColor={'#ECECEC'}
              textColor={'#454545'}
            />
          </span>

          <div className="flex flex-row gap-4">
            <label className={`flex items-center gap-2`}>
              <input
                disabled={continueAndReview}
                type="radio"
                checked={isMale}
                name="sexAtBirth"
                className={`${
                  isMale
                    ? `disabled:bg-gray-300 disabled:hover:bg-gray-300`
                    : `disabled:bg-gray-100`
                }`}
                value="Male"
                onChange={handleIsMale}
              />
              Male
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="sexAtBirth"
                checked={isFemale}
                className={`${
                  isFemale
                    ? `disabled:bg-gray-300 disabled:hover:bg-gray-300`
                    : `disabled:bg-gray-100`
                }`}
                disabled={continueAndReview}
                value="Female"
                onChange={handleIsFemale}
              />
              Female
            </label>
          </div>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="countryOfReference"
            className="flex items-center text-sm"
          >
            Country of Reference <span className="text-red-500">*</span>{' '}
          </label>
          <div className="desktop:w-[400px] flex w-full flex-col gap-1">
            <TailwindSelect
              disabled={continueAndReview || isFromUpdatePage}
              placeholder="Select country"
              options={countries.map(
                (country: { name: string }) => country.name
              )}
              value={selectedCountry || countryRef}
              onChange={handleCountry}
            />
          </div>
          <TailwindAlert
            className="desktop:w-[400px] w-full"
            message="The country where you have spent most of your life or where you have the closest ties. It's possible to have two."
          />
        </div>
      </div>
      <div className="desktop:w-[400px] flex w-full flex-col">
        <div className="flex flex-col">
          <label htmlFor="firstName" className="text-sm">
            First name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            disabled={continueAndReview || isFromUpdatePage}
            name="firstName"
            value={firstName}
            placeholder={'Enter first name'}
            onChange={handleFirstNameState}
            className={` mt-1 flex h-12 items-center justify-center  overflow-visible rounded-lg border border-gray-300 px-2 text-left text-xs font-medium shadow-none placeholder:overflow-visible placeholder:text-gray-400 focus:border-gray-400 focus:ring-1 focus:ring-gray-400 disabled:bg-gray-100 ${
              firstNameState.error
                ? 'border-red-400 focus:border-red-400 focus:ring-red-400'
                : ''
            }`}
          />
          {firstNameState.error && (
            <p className="pt-1 text-xs font-[300] text-red-500">
              Please enter a valid first name.
            </p>
          )}
        </div>
      </div>
      <div className="desktop:w-[400px] flex w-full flex-col gap-1">
        <div className="flex flex-col">
          <label htmlFor="lastName" className="text-sm">
            Last name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="lastName"
            value={lastName}
            disabled={continueAndReview || isFromUpdatePage}
            onChange={handleLastNameState}
            placeholder={'Enter last name'}
            className={` mt-1 flex h-12 items-center justify-center  overflow-visible rounded-lg border border-gray-300 px-2 text-left text-xs font-medium shadow-none placeholder:overflow-visible placeholder:text-gray-400 focus:border-gray-400 focus:ring-1 focus:ring-gray-400 disabled:bg-gray-100 ${
              lastNameState.error
                ? 'border-red-400 focus:border-red-400 focus:ring-red-400'
                : ''
            }`}
          />
          {lastNameState.error && (
            <p className="pt-1 text-xs font-[300] text-red-500">
              Please enter a valid last name.
            </p>
          )}
        </div>
      </div>
      {isFemale && (
        <div className="desktop:w-[400px] flex w-full flex-col">
          <label htmlFor="maidenName" className="text-sm">
            Maiden name <span className="text-gray-400">(optional)</span>
          </label>
          <input
            type="text"
            disabled={continueAndReview}
            name="maidenName"
            value={maidenName}
            placeholder="Enter maiden name"
            onChange={handleMaidenNameState}
            className={`mt-1 flex h-12 items-center  justify-center  overflow-visible rounded-lg border border-gray-300 px-2 text-left text-xs font-medium shadow-none placeholder:overflow-visible placeholder:text-gray-400 focus:border-gray-400 focus:ring-1 focus:ring-gray-400 disabled:bg-gray-100 ${
              maidenNameState.error
                ? 'border-red-400 focus:border-red-400 focus:ring-red-400'
                : ''
            }`}
          />
          {maidenNameState.error && (
            <p className="pt-1 text-xs font-[300] text-red-500">
              Please enter a valid name.
            </p>
          )}
        </div>
      )}
      {showMiddleName && (
        <div className="desktop:w-[400px] flex w-full flex-col">
          <label htmlFor="middleName" className="text-sm">
            Middle name <span className="text-gray-400">(optional)</span>
          </label>
          <input
            type="text"
            disabled={continueAndReview}
            name="middleName"
            value={middleName}
            placeholder="Enter middle name"
            onChange={handleMiddleNameState}
            className={
              'mt-1 flex h-12 items-center  justify-center  overflow-visible rounded-lg border border-gray-300 px-2 text-left text-xs font-medium shadow-none placeholder:overflow-visible placeholder:text-gray-400 focus:border-gray-400 focus:ring-1 focus:ring-gray-400 disabled:bg-gray-100'
            }
          />
        </div>
      )}

      <div>
        <SecondaryButton
          text={`${showMiddleName ? 'Hide middle name' : 'Add middle name'}`}
          leftIcon={true}
          disabled={continueAndReview}
          onClick={handleShowMiddleName}
          textClassName="text-xs text-white text-xs !font-normal sm:text-sm"
          extraButtonClassName="focus:!outline-none focus:!ring-none focus:shadow-none p-2 my-0 pl-0 flex gap-2 border-none !justify-start w-fit hover:!bg-transparent hover:shadow-none rounded-md"
        >
          {showMiddleName ? (
            <BiMinus fill="#257C6B" />
          ) : (
            <PlusIcon width={20} height={20} fill="#257C6B" />
          )}
        </SecondaryButton>
      </div>
    </div>
  );
}
