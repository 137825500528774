import type { IconProps } from '../types/icons';

export default function InformationIcon({
  className = '',
  width = 28,
  height = 28,
  fill = '#409F85',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width as number}
      height={height as number}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill as string}
      className={className as string}
    >
      <g id="Frame 245" transform={`scale(${width / 28},${height / 28})`}>
        <path
          id="Vector"
          d="M12.8333 10.5H15.1666V8.16665H12.8333M14 23.3333C8.85498 23.3333 4.66665 19.145 4.66665 14C4.66665 8.85498 8.85498 4.66665 14 4.66665C19.145 4.66665 23.3333 8.85498 23.3333 14C23.3333 19.145 19.145 23.3333 14 23.3333ZM14 2.33331C12.4679 2.33331 10.9508 2.63508 9.53534 3.22139C8.11987 3.80769 6.83375 4.66705 5.7504 5.7504C3.56248 7.93832 2.33331 10.9058 2.33331 14C2.33331 17.0942 3.56248 20.0616 5.7504 22.2496C6.83375 23.3329 8.11987 24.1923 9.53534 24.7786C10.9508 25.3649 12.4679 25.6666 14 25.6666C17.0942 25.6666 20.0616 24.4375 22.2496 22.2496C24.4375 20.0616 25.6666 17.0942 25.6666 14C25.6666 12.4679 25.3649 10.9508 24.7786 9.53534C24.1923 8.11987 23.3329 6.83375 22.2496 5.7504C21.1662 4.66705 19.8801 3.80769 18.4646 3.22139C17.0492 2.63508 15.5321 2.33331 14 2.33331ZM12.8333 19.8333H15.1666V12.8333H12.8333V19.8333Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
