import type { AddHumanRecord } from 'src/types/human';
import type { TidBitsV3Payload } from 'src/types/types';
import { calls } from 'src/utils/calls';
import { axiosInstance } from 'src/utils/instances';

export const getHumanHistory = (
  id: string,
  countryIso3: string,
  domain: string
) => {
  return axiosInstance(calls.getHumanHistory(id, countryIso3, domain))
    .then((res) => {
      return res.data?.data;
    })
    .catch((err) => console.log(err));
};

export const getHumanById = (
  id: string,
  countryIso3: string,
  domain: string
) => {
  return axiosInstance(calls.searchByHumanId(id, countryIso3, domain))
    .then((res) => {
      return res.data?.data;
    })
    .catch((err) => console.log(err));
};

export const getTidBits = (params: object, domain: string) => {
  return axiosInstance(calls.getTidBits(params, domain))
    .then((res) => {
      return res.data?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const getTidBitsV2 = (params: object, domain: string) => {
  return axiosInstance(calls.getTidBitsV2(params, domain))
    .then((res) => {
      return res.data?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const getTidBitsV3 = (data: TidBitsV3Payload, domain: string) => {
  return axiosInstance(calls.getTidBitsV3(data, domain))
    .then((res) => {
      return res?.data?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const getHumanByIdClientSide = (
  id: string,
  countryIso3: string,
  domain: string
) => {
  return axiosInstance(calls.searchByHumanIdClientSide(id, countryIso3, domain))
    .then((res) => {
      return res.data?.data;
    })
    .catch((err) => console.log(err));
};

export const getHumanHistoryServerProps = (
  id: string,
  countryIso3: string,
  domain: string
) => {
  return axiosInstance(
    calls.getHumanHistoryServerProps(id, countryIso3, domain)
  )
    .then((res) => {
      return res.data?.data;
    })
    .catch((err) => console.log(err));
};

export const addHumanRecord = (
  humanRecord: AddHumanRecord,
  domainName: string
) => {
  return axiosInstance(calls.addHumanRecord(humanRecord, domainName))
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteHumanRecord = (
  humanId: string,
  countryIso3: string,
  domainName: string
) => {
  return axiosInstance(
    calls.deleteHumanRecord(humanId, countryIso3, domainName)
  )
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};
