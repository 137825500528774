import type { ChangeEvent } from 'react';
import type { LocationsTypes } from 'src/types/edit-create-types';
import type { DataWorldChartTypes } from 'src/types/types';
import BirthDetails from './BirthDetails';
import DeathDetails from './DeathDetails';
import MainInformation from './MainInformation';

interface EditHumanFormProps {
  countries: DataWorldChartTypes[];
  allCountries: LocationsTypes[];
  handleFirstName: (value: string) => void;
  handleLastName: (value: string) => void;
  handleMaidenName: (value: string) => void;
  handleMiddleName: (value: string) => void;
  onSelectCountry: (country: DataWorldChartTypes | undefined) => void;
  handleIsMale: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleIsFemale: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBirthCity: (value: string) => void;
  handleBirthDay: (value: ChangeEvent<HTMLInputElement>) => void;
  handleBirthMonth: (value: string) => void;
  handleBirthYear: (value: ChangeEvent<HTMLInputElement>) => void;
  handleBirthRegion: (value: string) => void;
  handleBirthMargin: (value: string) => void;
  handleBirthCountry: (value: string) => void;
  handleDeathCity: (value: string) => void;
  handleDeathRegion: (value: string) => void;
  handleDeathCountry: (value: string) => void;
  handleDeathDay: (value: ChangeEvent<HTMLInputElement>) => void;
  handleDeathMonth: (value: string) => void;
  handleDeathYear: (value: ChangeEvent<HTMLInputElement>) => void;
  handleIsInferred: (value: ChangeEvent<HTMLInputElement>) => void;
  continueAndReview: boolean;
  isFemale: boolean;
  isMale: boolean;
  firstName?: string;
  lastName?: string;
  countryRef?: string;
  birthCity?: string;
  birthRegion?: string;
  birthCountry?: string;
  deathCity?: string;
  deathRegion?: string;
  deathCountry?: string;
  middleName?: string;
  maidenName?: string;
  isFromUpdatePage?: boolean;
  birthDate?: {
    day?: string;
    month?: string;
    year?: string;
    approximateYear?: string;
  };
  deathDate?: {
    day?: string;
    month?: string;
    year?: string;
  };
}
export default function EditHumanForm({
  countries,
  allCountries,
  handleFirstName,
  handleLastName,
  handleMaidenName,
  handleMiddleName,
  onSelectCountry,
  handleBirthCity,
  handleIsMale,
  handleIsFemale,
  handleBirthDay,
  handleBirthMonth,
  handleBirthYear,
  handleBirthMargin,
  handleBirthRegion,
  handleBirthCountry,
  handleDeathCity,
  handleDeathRegion,
  handleDeathCountry,
  handleDeathDay,
  handleDeathMonth,
  handleDeathYear,
  handleIsInferred,
  continueAndReview,
  isFemale,
  isMale,
  firstName,
  lastName,
  countryRef,
  middleName,
  maidenName,
  birthDate,
  deathDate,
  birthCity,
  birthRegion,
  birthCountry,
  deathCity,
  deathRegion,
  deathCountry,
  isFromUpdatePage = false,
}: EditHumanFormProps) {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-6">
      <MainInformation
        countries={countries}
        handleFirstName={handleFirstName}
        handleLastName={handleLastName}
        handleMaidenName={handleMaidenName}
        handleIsMale={handleIsMale}
        handleIsFemale={handleIsFemale}
        handleMiddleName={handleMiddleName}
        onSelectCountry={onSelectCountry}
        continueAndReview={continueAndReview}
        isFemale={isFemale}
        isMale={isMale}
        firstName={firstName}
        lastName={lastName}
        countryRef={countryRef}
        middleName={middleName}
        maidenName={maidenName}
        isFromUpdatePage={isFromUpdatePage}
      />
      <BirthDetails
        countries={allCountries}
        countryRef={countryRef}
        handleBirthDay={handleBirthDay}
        handleBirthMonth={handleBirthMonth}
        handleBirthYear={handleBirthYear}
        handleBirthCity={handleBirthCity}
        handleBirthRegion={handleBirthRegion}
        handleBirthCountry={handleBirthCountry}
        handleIsInferred={handleIsInferred}
        handleBirthMargin={handleBirthMargin}
        birthDate={birthDate}
        continueAndReview={continueAndReview}
        birthCity={birthCity}
        birthRegion={birthRegion}
        birthCountry={birthCountry}
        deathDate={deathDate}
      />
      <DeathDetails
        countries={allCountries}
        countryRef={countryRef}
        handleDeathDay={handleDeathDay}
        handleDeathMonth={handleDeathMonth}
        handleDeathYear={handleDeathYear}
        handleDeathCity={handleDeathCity}
        handleDeathRegion={handleDeathRegion}
        handleDeathCountry={handleDeathCountry}
        deathDate={deathDate}
        continueAndReview={continueAndReview}
        deathCity={deathCity}
        deathRegion={deathRegion}
        deathCountry={deathCountry}
        birthDate={birthDate}
      />
    </div>
  );
}
