import type { IconProps } from '../types/icons';

export default function GrayCheckCircle({
  width = 20,
  height = 20,
  fill = '#9FA3A9',
  className = '',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        d="M11 0.0297852V0.0497851V2.04979C15.39 2.58979 18.5 6.57979 17.96 10.9698C17.5 14.6098 14.64 17.4998 11 17.9298V19.9298C16.5 19.3798 20.5 14.4998 19.95 8.99979C19.5 4.24979 15.73 0.499785 11 0.0297852ZM9 0.0597851C7.05 0.249785 5.19 0.999785 3.67 2.25979L5.1 3.73978C6.22 2.83979 7.57 2.25979 9 2.05979V0.0597851ZM2.26 3.66979C1 5.18979 0.250003 7.03979 0.0500031 8.99979H2.05C2.24 7.57979 2.8 6.22978 3.69 5.09978L2.26 3.66979ZM13.5 6.49979L8.62 11.3798L6.5 9.25979L5.44 10.3198L8.62 13.4998L14.56 7.55979L13.5 6.49979ZM0.060003 10.9998C0.260003 12.9598 1.03 14.8098 2.27 16.3298L3.69 14.8998C2.81 13.7698 2.24 12.4198 2.06 10.9998H0.060003ZM5.1 16.3698L3.67 17.7398C5.18 18.9998 7.04 19.7898 9 19.9998V17.9998C7.58 17.8198 6.23 17.2498 5.1 16.3698Z"
        fill={fill}
      />
    </svg>
  );
}
