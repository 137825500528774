import InformationIcon from 'src/assets/InformationIcon';
import SucceedIconFull from 'src/assets/SucceedIconFull';

type BasicInfoCardProps = {
  country: string;
  lastName?: string;
  firstName?: string;
  isMale?: boolean;
  isFemale?: boolean;
};

export default function BasicInfoCard({
  country,
  firstName,
  lastName,
  isMale,
  isFemale,
}: BasicInfoCardProps) {
  if (country && lastName && firstName && (isMale || isFemale)) return <></>;

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <div className="flex gap-3">
        <InformationIcon fill="#9FA3A9" className="h-6 w-6" />
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-semibold">
            Please start by providing the basic information...
          </h4>
          <ul className="list-style-none flex flex-col gap-1 font-[500] text-sm text-gray-600">
            <li
              className={`${
                isMale || isFemale ? 'text-[#257C6B]' : ''
              } flex items-center gap-2`}
            >
              {isMale || isFemale ? (
                <SucceedIconFull className="w-4 h-4  bg-green" />
              ) : (
                <div className="w-4 h-4 border border-3 rounded-full border-gray-400" />
              )}
              Sex at birth
            </li>
            <li
              className={`${
                country ? 'text-[#257C6B]' : ''
              } flex items-center gap-2`}
            >
              {country ? (
                <SucceedIconFull className="w-4 h-4  bg-green" />
              ) : (
                <div className="w-4 h-4 border border-3 rounded-full border-gray-400" />
              )}
              Country of reference
            </li>
            <li
              className={`${
                firstName ? 'text-[#257C6B]' : ''
              } flex items-center gap-2`}
            >
              {firstName ? (
                <SucceedIconFull className="w-4 h-4  bg-green" />
              ) : (
                <div className="w-4 h-4 border border-3 rounded-full border-gray-400" />
              )}
              First name
            </li>
            <li
              className={`${
                lastName ? 'text-[#257C6B]' : ''
              } flex items-center gap-2`}
            >
              {lastName ? (
                <SucceedIconFull className="w-4 h-4  bg-green" />
              ) : (
                <div className="w-4 h-4 border border-3 rounded-full border-gray-400" />
              )}
              Last name
            </li>
          </ul>{' '}
        </div>
      </div>
    </div>
  );
}
