import HumanDetailsContainer from '../human-details/human-details-container';

// eslint-disable-next-line
export default function ReviewDetails({ human }: any) {
  return (
    <div className="flex flex-col gap-1 py-4">
      <h4 className="text-base">Review Details</h4>
      <p className="text-sm text-gray-400 font-normal mb-2">
        Please review the details below. Make sure the data you have entered is
        correct and error-free
      </p>
      <HumanDetailsContainer
        className="bg-[#F2FBF8] p-4 rounded-lg"
        human={human}
      />
    </div>
  );
}
