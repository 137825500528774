import { debounce, isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { searchByFilters } from 'src/actions/searchActions';
import type {
  FemaleCompletionObjectTypes,
  ListRegionStatesTypes,
  LocationsTypes,
  MaleCompletionObjectTypes,
  PathTypes,
} from 'src/types/edit-create-types';
import type { DataWorldChartTypes } from 'src/types/types';
import { calculateCompletionIndex } from 'src/utils/calculateCompletionIndex';
import { checkParams } from 'src/utils/checkParams';
import BasicInfoCard from './basic-info-card';
import InfoBox from './info-box';
import ReviewDetails from './review-details';
import TableSimilarRecords from './table-similar-records';

interface CompletionStatusProps {
  isMale: boolean;
  isFemale: boolean;
  firstName: string;
  lastName: string;
  country: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  deathDay: string;
  deathMonth: string;
  deathYear: string;
  countries: LocationsTypes[] | DataWorldChartTypes[];
  birthCountry: string;
  birthCity: string;
  birthRegion: string;
  deathCountry: string;
  deathCity: string;
  deathRegion: string;
  maidenName: string;
  firstName2: string;
  isInferred: boolean | string;
  listOfRegionsStates: ListRegionStatesTypes[];
  continueAndReview: boolean;
  allCountriesWithRegions: LocationsTypes[];
  pathsForLocation: PathTypes[];
  listOfRegionsStatesDeath: ListRegionStatesTypes[];
  pathsForLocationDeath: PathTypes[];
  isTypingInFirstName1: boolean;
  isTypingInFirstName2: boolean;
  isTypingInLastName: boolean;
  firstCondition: boolean;
  nrOfSimilarRecords?: number;
  handleNrOfSimilarRecords?: (value: number) => void;
}
export default function CompletationStatus({
  isMale,
  isFemale,
  firstName,
  lastName,
  country,
  birthDay,
  birthMonth,
  birthYear,
  deathDay,
  deathMonth,
  deathYear,
  countries,
  birthCountry,
  birthCity,
  birthRegion,
  deathCountry,
  deathCity,
  deathRegion,
  maidenName,
  firstName2,
  isInferred,
  listOfRegionsStates,
  continueAndReview,
  allCountriesWithRegions,
  pathsForLocation,
  listOfRegionsStatesDeath,
  pathsForLocationDeath,
  isTypingInFirstName1,
  isTypingInFirstName2,
  isTypingInLastName,
  firstCondition,
  nrOfSimilarRecords,
  handleNrOfSimilarRecords,
}: CompletionStatusProps) {
  const [isLoadingSimilarRecords, setIsLoadingSimilarRecords] = useState(false);
  const [arrayFromServer, setArrayFromServer] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [numberOfSimilarRecords, setNumberOfSimilarRecords] = useState(0);
  const [isUniqueRecord, setIsUniqueRecord] = useState(false);
  const [isValidRecord, setIsValidRecord] = useState(false);
  const router = useRouter();
  const [completionBarWidthState, setCompletionBarWidthState] =
    useState<number>(0);

  useEffect(() => {
    handleNrOfSimilarRecords &&
      handleNrOfSimilarRecords(numberOfSimilarRecords);
  }, [numberOfSimilarRecords]);

  const searchForSimilarRecords: () => void = debounce(() => {
    setIsLoadingSimilarRecords(true);

    const countryIso3 = (countries as DataWorldChartTypes[])?.find(
      (countryProps: DataWorldChartTypes) =>
        countryProps?.name?.toLowerCase() === country?.toLowerCase()
    )?.iso_3;

    const params = {
      first_name2: '',
      birth_day: '',
      birth_month: '',
      birth_year: '',
      birth_country: '',
      birth_region: '',
      birth_city: '',
      death_day: '',
      death_month: '',
      death_year: '',
      death_country: '',
      death_region: '',
      death_city: '',
      sex: '',
      alive: 'both',
      maiden_name: '',
      is_inferred: 'false',
    };

    for (const value in params) {
      if (firstName2?.length > 0) {
        params.first_name2 = firstName2.trim();
      }

      if (maidenName?.length > 0) {
        params.maiden_name = maidenName.trim();
      }

      if (birthDay?.length > 0) {
        params.birth_day = birthDay;
      }

      if (birthMonth?.length > 0) {
        params.birth_month = birthMonth;
      }

      if (birthYear?.length > 0) {
        params.birth_year = birthYear;
      }

      if (deathDay?.length > 0) {
        params.death_day = deathDay;
      }

      if (deathMonth?.length > 0) {
        params.death_month = deathMonth;
      }

      if (deathYear?.length > 0) {
        params.death_year = deathYear;
      }

      if (isInferred) {
        params.is_inferred = 'true';
      }

      if (birthCountry?.length > 0) {
        const birthCountryId = allCountriesWithRegions?.find(
          (locationCountry: LocationsTypes) =>
            locationCountry?.place?.toLowerCase() ===
            birthCountry?.toLowerCase()
        )?.id as string;
        params.birth_country = birthCountryId ? birthCountryId?.toString() : '';
      }

      if (birthRegion?.length > 0) {
        const birthRegionId = listOfRegionsStates?.find(
          (locationRegionState: ListRegionStatesTypes) =>
            locationRegionState?.place?.toLowerCase() ===
            birthRegion?.toLowerCase()
        )?.id;
        params.birth_region = birthRegionId ? birthRegionId?.toString() : '';
      }

      if (birthCity?.length > 0) {
        const birthCityId = pathsForLocation?.find(
          (locationPath: PathTypes) =>
            locationPath?.place?.toLowerCase() === birthCity?.toLowerCase()
        )?.id;
        params.birth_city = birthCityId ? birthCityId?.toString() : '';
      }

      if (deathCountry?.length > 0) {
        const deathCountryId = allCountriesWithRegions?.find(
          (locationCountry: LocationsTypes) =>
            locationCountry?.place?.toLowerCase() ===
            deathCountry?.toLowerCase()
        )?.id as string;
        params.death_country = deathCountryId ? deathCountryId?.toString() : '';
      }

      if (deathRegion?.length > 0) {
        const deathRegionId = (
          listOfRegionsStatesDeath as LocationsTypes[]
        )?.find(
          (locationRegionState: LocationsTypes) =>
            locationRegionState?.place?.toLowerCase() ===
            deathRegion?.toLowerCase()
        )?.id;
        params.death_region = deathRegionId ? deathRegionId?.toString() : '';
      }

      if (deathCity?.length > 0) {
        const deathCityId = (pathsForLocationDeath as LocationsTypes[])?.find(
          (locationPath: LocationsTypes) =>
            locationPath?.place?.toLowerCase() === deathCity?.toLowerCase()
        )?.id;
        params.death_city = deathCityId ? deathCityId?.toString() : '';
      }

      if (isMale) {
        params.sex = 'male';
      }

      if (isFemale) {
        params.sex = 'female';
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ((params[value] as string)?.length === 0 || !params[value]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete params[value];
      }
    }

    if (checkParams(params)) {
      const domainName = (countries as DataWorldChartTypes[])?.find(
        (countryFromProps: { iso_3: string }) => {
          return (
            countryFromProps?.iso_3?.toLowerCase() ===
            countryIso3?.toLowerCase()
          );
        }
      )?.api_public_domain_name as string;

      if (isEmpty(params)) {
        searchByFilters(
          firstName,
          lastName,
          countryIso3,
          '1',
          '20',
          undefined,
          domainName
        )
          .then((res) => {
            if (res?.data) {
              const totalMatches = res?.totalMatches;

              setArrayFromServer(res?.data || []);

              if (totalMatches < 25 && totalMatches !== 0) {
                setShowTable(true);
              }
              if (totalMatches === 0 || totalMatches > 25) {
                setShowTable(false);
              }

              setNumberOfSimilarRecords(totalMatches || 0);
            } else {
              setArrayFromServer([]);
              setShowTable(false);
              setNumberOfSimilarRecords(0);
              setIsUniqueRecord(true);
              setIsValidRecord(true);
            }
          })
          .catch((error) => {
            if (error) {
              setArrayFromServer([]);
              setShowTable(false);
              setNumberOfSimilarRecords(0);
              setIsUniqueRecord(false);
              setIsValidRecord(false);
            }
          })
          .finally(() => {
            // setTimeout in this case is to give time to Next.js to rerender the getSimilarRecords function
            setTimeout(() => {
              setIsLoadingSimilarRecords(false);
            }, 300);
          });
      } else {
        searchByFilters(
          firstName,
          lastName,
          countryIso3,
          '1',
          '20',
          new URLSearchParams(params)?.toString(),
          domainName
        )
          .then((res) => {
            if (res?.data) {
              setArrayFromServer(
                res?.data?.filter(
                  (obj: { id: string }) =>
                    obj.id !=
                    router?.query?.human_slug?.toString()?.split('-')?.[0]
                )
              );

              const totalMatches = res?.data?.filter(
                (obj: { id: string }) =>
                  obj.id !=
                  router?.query?.human_slug?.toString()?.split('-')?.[0]
              )?.length;

              if (totalMatches < 25 && totalMatches !== 0) {
                setShowTable(true);
              }
              if (totalMatches === 0 || totalMatches > 25) {
                setShowTable(false);
              }

              setNumberOfSimilarRecords(totalMatches || 0);
            } else {
              setArrayFromServer([]);
              setShowTable(false);
              setNumberOfSimilarRecords(0);
              setIsUniqueRecord(true);
              setIsValidRecord(true);
            }
          })
          .catch((error) => {
            if (error) {
              setArrayFromServer([]);
              setShowTable(false);
              setNumberOfSimilarRecords(0);
              setIsUniqueRecord(false);
              setIsValidRecord(false);
            }
          })
          .finally(() => {
            // SetTimeout in this case is to give time to Next.js to rerender the getSimilarRecords function
            setTimeout(() => {
              setIsLoadingSimilarRecords(false);
            }, 300);
          });
      }
    }
  }, 500);

  const getCompletionBarWidth = () => {
    const maleArrayWithDeath: MaleCompletionObjectTypes = {
      firstName1: firstName,
      firstName2,
      lastName,
      sex: isMale ? 'male' : '',
      country,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
      deathDay,
      deathMonth,
      deathYear,
      deathCity,
      deathRegion,
      deathCountry,
    };

    const femaleArrayWithDeath: FemaleCompletionObjectTypes = {
      firstName1: firstName,
      firstName2,
      lastName,
      country,
      sex: isFemale ? 'female' : '',
      maidenName,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
      deathDay,
      deathMonth,
      deathYear,
      deathCity,
      deathRegion,
      deathCountry,
    };

    const maleArrayWithoutDeath: Omit<
      MaleCompletionObjectTypes,
      | 'deathDay'
      | 'deathMonth'
      | 'deathYear'
      | 'deathCity'
      | 'deathRegion'
      | 'deathCountry'
    > = {
      firstName1: firstName,
      firstName2,
      lastName,
      sex: isMale ? 'male' : '',
      country,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
    };

    const femaleArrayWithoutDeath: Omit<
      FemaleCompletionObjectTypes,
      | 'deathDay'
      | 'deathMonth'
      | 'deathYear'
      | 'deathCity'
      | 'deathRegion'
      | 'deathCountry'
    > = {
      firstName1: firstName,
      firstName2,
      lastName,
      country,
      sex: isFemale ? 'female' : '',
      birthDay,
      maidenName,
      birthMonth,
      birthYear,
      birthCity,
      birthCountry,
      birthRegion,
    };

    const includesDeathRelatedInformation =
      deathCity?.length > 0 ||
      deathRegion?.length > 0 ||
      deathCountry?.length > 0 ||
      deathMonth?.length > 0 ||
      deathYear?.length > 0 ||
      deathDay?.length > 0;

    let completionObject;

    if (includesDeathRelatedInformation) {
      completionObject = isMale ? maleArrayWithDeath : femaleArrayWithDeath;
    } else {
      completionObject = isMale
        ? maleArrayWithoutDeath
        : femaleArrayWithoutDeath;
    }

    const percentage = calculateCompletionIndex(completionObject);

    if (
      isTypingInLastName === false &&
      isTypingInFirstName1 === false &&
      isTypingInFirstName2 === false &&
      country?.length > 0 &&
      (isMale || isFemale)
    ) {
      setCompletionBarWidthState(percentage);
    }
  };

  useEffect(() => {
    const zeroCondition =
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      country?.length > 0 &&
      (isMale || isFemale);

    if (
      isTypingInLastName === false &&
      isTypingInFirstName1 === false &&
      country?.length > 0 &&
      (isMale || isFemale)
    ) {
      getCompletionBarWidth();
    }

    if (
      firstName?.length === 0 ||
      lastName?.length === 0 ||
      country?.length === 0
    ) {
      setShowTable(false);
      setArrayFromServer([]);
      setNumberOfSimilarRecords(0);
      setIsUniqueRecord(false);
      setIsValidRecord(false);
    }

    if (zeroCondition && firstCondition) {
      searchForSimilarRecords();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    firstName2,
    lastName,
    country,
    birthCountry,
    birthCity,
    birthRegion,
    birthDay,
    birthMonth,
    birthYear,
    maidenName,
    deathDay,
    deathMonth,
    deathYear,
    deathCountry,
    deathCity,
    deathRegion,
    deathCountry,
    isMale,
    isFemale,
    firstCondition,
    listOfRegionsStates,
    isTypingInFirstName1,
    isTypingInLastName,
    listOfRegionsStatesDeath,
  ]);

  return (
    <div className="w-full bg-white p-6  font-semibold text-lg rounded-lg">
      <h4 className="mb-4">Completion Status</h4>

      <div className="flex flex-col gap-4">
        <div className={`w-full h-[11px] bg-gray-200 rounded-lg z-1`}>
          <div
            style={{
              width: completionBarWidthState?.toString() + '%',
              transition: '300ms',
            }}
            className={`z-[2] h-[11px] ${
              completionBarWidthState < 50 ? 'bg-[#FFA35C]' : 'bg-[#409F85] '
            } rounded-lg`}
          />
        </div>
        <div className=" text-xs font-[300] flex items-center justify-between">
          <span>Completion:</span>{' '}
          <span>{completionBarWidthState?.toString()}%</span>
        </div>
        {!continueAndReview && (
          <InfoBox
            firstName={firstName}
            lastName={lastName}
            numberOfSimilarRecords={numberOfSimilarRecords}
            isMale={isMale}
            isFemale={isFemale}
            country={country}
          />
        )}
        {!continueAndReview && (
          <BasicInfoCard
            firstName={firstName}
            lastName={lastName}
            isMale={isMale}
            isFemale={isFemale}
            country={country}
          />
        )}
        {continueAndReview && (
          <ReviewDetails
            human={{
              firstName,
              lastName,
              maidenName,
              ref_country: country,
              middleName: firstName2,
              sex: isMale ? 'Male' : 'Female',
              birth: {
                birth_day: birthDay,
                birth_month: birthMonth,
                birth_year: birthYear,
                birth_place: birthRegion,
                birth_city: birthCity,
                birth_country: birthCountry,
              },
              death: {
                death_day: deathDay,
                death_month: deathMonth,
                death_year: deathYear,
                death_place: deathRegion,
                death_city: deathCity,
                death_country: deathCountry,
              },
            }}
          />
        )}
      </div>
      {numberOfSimilarRecords > 0 && (
        <TableSimilarRecords
          similarRecords={arrayFromServer}
          countryFromProps={country}
        />
      )}
    </div>
  );
}
