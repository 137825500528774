import type {
  HumanPersonTypes,
  PersonHistory,
} from 'src/types/edit-history-types';

export const getAllChangesFromPersonHistory = (
  personHistory: PersonHistory[]
) => {
  const allChanges: HumanPersonTypes[] = [];
  personHistory?.forEach((history) => {
    history?.changes?.forEach((person) => {
      if (person?.field_name && person?.new_value) {
        allChanges.push({
          field_name: person?.field_name,
          new_value: person?.new_value,
        });
      }
    });
  });

  const uniqueChanges: HumanPersonTypes[] = [];

  for (let i = 0; i < allChanges.length; i++) {
    const change = allChanges[i];
    const isDuplicate = uniqueChanges.some(
      (uniqueChange) => uniqueChange?.field_name === change?.field_name
    );
    if (!isDuplicate) {
      uniqueChanges.push(change);
    }

    if (isDuplicate) {
      uniqueChanges.forEach((uniqueChange) => {
        if (uniqueChange?.field_name === change?.field_name) {
          uniqueChange.new_value = change?.new_value;
        }
      });
    }
  }

  return allChanges;
};
