import type { LocationsTypes } from 'src/types/edit-create-types';

export default function moveCountryToFirstPlace(
  countries: LocationsTypes[],
  countryName?: string
) {
  if (!countryName) {
    return countries;
  }
  const index = countries.findIndex((country) => country.place === countryName);

  if (index === -1) {
    return countries;
  }

  const movedCountry = countries[index];

  const updatedCountries = [
    movedCountry,
    ...countries.slice(0, index),
    ...countries.slice(index + 1),
  ];

  return updatedCountries;
}
