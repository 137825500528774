import type { IconProps } from '../types/icons';

export default function SucceedIconFull({
  width = 18,
  height = 18,
  fill = '#34A28A',
  className = '',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        d="M8.99984 0.666504C4.4165 0.666504 0.666504 4.4165 0.666504 8.99984C0.666504 13.5832 4.4165 17.3332 8.99984 17.3332C13.5832 17.3332 17.3332 13.5832 17.3332 8.99984C17.3332 4.4165 13.5832 0.666504 8.99984 0.666504ZM7.33317 13.1665L3.1665 8.99984L4.3415 7.82484L7.33317 10.8082L13.6582 4.48317L14.8332 5.6665L7.33317 13.1665Z"
        fill={fill}
      />
    </svg>
  );
}
