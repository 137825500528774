import GrayCheckCircle from 'src/assets/GrayCheckCircle';
import SucceedIcon from 'src/assets/SucceedIcon';

type InfoBoxProps = {
  numberOfSimilarRecords: number;
  country: string;
  lastName?: string;
  firstName?: string;
  isMale?: boolean;
  isFemale?: boolean;
};

export default function InfoBox({
  numberOfSimilarRecords,
  country,
  lastName,
  firstName,
  isMale,
  isFemale,
}: InfoBoxProps) {
  if (!country || !lastName || !firstName || (!isMale && !isFemale))
    return <></>;

  return (
    <div>
      <h4 className="py-4 text-base flex items-center gap-2">
        {' '}
        <p className="font-semibold text-base">Similar Records: </p>
        {numberOfSimilarRecords === 0 ? (
          <span className="text-[#197C6B] ">No Similar records</span>
        ) : (
          <p>
            <span className="text-red-400">{numberOfSimilarRecords}</span>{' '}
            (results)
          </p>
        )}
      </h4>

      <div
        className={`flex gap-2 ${
          numberOfSimilarRecords === 0 ? 'bg-[#F2FBF8]' : 'bg-gray-100'
        } p-4 rounded-lg`}
      >
        {numberOfSimilarRecords === 0 ? (
          <SucceedIcon className="h-5 w-5" />
        ) : (
          <GrayCheckCircle />
        )}
        {numberOfSimilarRecords === 0 && (
          <div className="text-sm flex flex-col gap-2b ">
            <p>
              Good news: there are no other records with these exact same
              characteristics
            </p>
            <p className="text-sm font-normal text-gray-500">
              You can create your record, unless you want to add still more
              information
            </p>
          </div>
        )}
        {numberOfSimilarRecords < 10 && numberOfSimilarRecords > 0 && (
          <div className="text-sm flex flex-col gap-2">
            <p>Every bit of information helps make your record more unique.</p>
            <p className="text-sm font-normal text-gray-500">Keep at it!</p>
          </div>
        )}
        {numberOfSimilarRecords > 10 && (
          <div className="text-sm flex flex-col gap-2">
            <p>Your record can only be created if it's unique</p>
            <p className="text-sm font-normal text-gray-500">
              Keep adding information or edit one of the similar records
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
