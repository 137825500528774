import type {
  FemaleCompletionObjectTypes,
  MaleCompletionObjectTypes,
} from 'src/types/edit-create-types';

export const calculateCompletionIndex = (
  completionObject:
    | MaleCompletionObjectTypes
    | FemaleCompletionObjectTypes
    | Omit<
        MaleCompletionObjectTypes,
        | 'deathDay'
        | 'deathMonth'
        | 'deathYear'
        | 'deathCity'
        | 'deathRegion'
        | 'deathCountry'
      >
    | Omit<
        FemaleCompletionObjectTypes,
        | 'deathDay'
        | 'deathMonth'
        | 'deathYear'
        | 'deathCity'
        | 'deathRegion'
        | 'deathCountry'
      >
) => {
  let percentage = 0;
  let isDead = false;

  const areAllElementsFilled = Object.values(completionObject)?.every(
    (value: string) => value?.length > 0
  );

  if (areAllElementsFilled) {
    return 100;
  }

  const deathCompletionObject = completionObject as Pick<
    MaleCompletionObjectTypes,
    | 'deathCity'
    | 'deathCountry'
    | 'deathDay'
    | 'deathMonth'
    | 'deathRegion'
    | 'deathYear'
  >;

  // Check if the person has death-related information
  if (
    deathCompletionObject?.deathDay?.length > 0 ||
    deathCompletionObject?.deathMonth?.length > 0 ||
    deathCompletionObject?.deathYear?.length > 0 ||
    deathCompletionObject?.deathCity?.length > 0 ||
    deathCompletionObject?.deathRegion?.length > 0 ||
    deathCompletionObject?.deathCountry?.length > 0
  ) {
    isDead = true;
  }

  percentage += 15 * (completionObject?.firstName1?.length > 0 ? 1 : 0);
  percentage += 15 * (completionObject?.lastName?.length > 0 ? 1 : 0);
  percentage += isDead
    ? 5 * (completionObject?.firstName2?.length > 0 ? 1 : 0)
    : 15 * (completionObject?.firstName2?.length > 0 ? 1 : 0);

  percentage += isDead
    ? completionObject?.sex === 'male'
      ? 5
      : 2
    : completionObject?.sex === 'male'
    ? 10
    : 5;

  percentage += isDead
    ? (completionObject as FemaleCompletionObjectTypes)?.maidenName?.length > 0
      ? 3
      : 0
    : (completionObject as FemaleCompletionObjectTypes)?.maidenName?.length > 0
    ? 5
    : 0;

  percentage += isDead
    ? 5 * (completionObject?.birthYear?.length > 0 ? 1 : 0)
    : 15 * (completionObject?.birthYear?.length > 0 ? 1 : 0);

  percentage += 5 * (completionObject?.birthMonth?.length > 0 ? 1 : 0);
  percentage += 5 * (completionObject?.birthDay?.length > 0 ? 1 : 0);
  percentage += 5 * (completionObject?.birthCountry?.length > 0 ? 1 : 0);
  percentage += 5 * (completionObject?.birthRegion?.length > 0 ? 1 : 0);
  percentage += isDead
    ? 5 * (completionObject?.birthCity?.length > 0 ? 1 : 0)
    : 10 * (completionObject?.birthCity?.length > 0 ? 1 : 0);

  if (isDead) {
    // Factors specific to dead persons
    percentage += 5 * (deathCompletionObject?.deathYear?.length > 0 ? 1 : 0);
    percentage += 5 * (deathCompletionObject?.deathMonth?.length > 0 ? 1 : 0);
    percentage += 5 * (deathCompletionObject?.deathDay?.length > 0 ? 1 : 0);
    percentage += 5 * (deathCompletionObject?.deathCountry?.length > 0 ? 1 : 0);
    percentage += 5 * (deathCompletionObject?.deathRegion?.length > 0 ? 1 : 0);
    percentage += 5 * (deathCompletionObject?.deathCity?.length > 0 ? 1 : 0);
  }

  return percentage;
};
