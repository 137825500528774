/* eslint-disable @typescript-eslint/naming-convention */
type Params = {
  first_name2?: string;
  birth_day?: string;
  birth_month?: string;
  birth_year?: string;
  birth_country?: string;
  birth_region?: string;
  birth_city?: string;
  death_day?: string;
  death_month?: string;
  death_year?: string;
  death_country?: string;
  death_region?: string;
  death_city?: string;
  sex?: string;
  alive?: string;
};

export const checkParams = (params: Params): boolean => {
  if (
    params?.birth_day?.includes('/') ||
    params?.birth_month?.includes('/') ||
    params?.birth_year?.includes('/') ||
    (params?.birth_month as string)?.length > 2
  ) {
    return false;
  }
  if (
    params?.death_day?.includes('/') ||
    params?.death_month?.includes('/') ||
    params?.death_year?.includes('/') ||
    (params?.death_month as string)?.length > 2
  ) {
    return false;
  }
  return true;
};
