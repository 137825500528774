import moment from 'moment';
import { forwardRef } from 'react';
import type { Ref } from 'react';
import getFullName from 'src/utils/getFullName';
import { capitalizeString } from 'src/utils/string';
interface HumanDetailsProps {
  // eslint-disable-next-line
  human: any;
  className?: string;
  showMissingFields?: boolean;
}

function calculateAge(birth: {
  birth_day?: number;
  birth_month?: number;
  birth_year?: number;
}) {
  const { birth_day, birth_month, birth_year } = birth;

  if (!birth_year) {
    return 0;
  }

  const day = birth_day || 1;
  const month = birth_month ? birth_month - 1 : 0;

  const birthDate = new Date(birth_year, month, day);

  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  const monthDiff = currentDate.getMonth() - birthDate.getMonth();
  const dayDiff = currentDate.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return +age;
}

// eslint-disable-next-line
const HumanDetailsContainer = forwardRef<HTMLDivElement, HumanDetailsProps>(
  ({ human, className, showMissingFields = false }, ref) => {
    // eslint-disable-next-line
    const details: any = [];
    Object.keys(human).forEach((key, index) => {
      if (key === 'firstName') {
        details.push(
          <li
            key={key + index}
            className="flex flex-wrap justify-between gap-1"
          >
            <div className="font-normal">Full Name:</div>
            <div className="text-right font-bold">{getFullName(human)}</div>
          </li>
        );
      }
      if (key === 'middleName' && !human?.middleName && showMissingFields) {
        details.push(
          <li key={key + index} className="flex justify-between">
            <div className="font-normal">Middle Name:</div>
            <div className="font-bold text-[#D94F63]">Needs Completion</div>
          </li>
        );
      }
      if (key === 'ref_country' && human[key]) {
        details.push(
          <li key={key + index} className="flex justify-between">
            <div className="font-normal">Reference Country:</div>
            <div className="text-right font-bold ">{human[key]}</div>
          </li>
        );
      }
      if (
        key === 'birth' &&
        (human[key]?.birth_year ||
          human[key]?.birth_month ||
          human[key]?.birth_day)
      ) {
        Object.keys(human[key]).forEach((birth_key) => {
          if (
            birth_key === 'birth_day' &&
            !human[key]?.birth_day &&
            showMissingFields
          ) {
            details.push(
              <li key={birth_key} className="flex justify-between">
                <div className="font-normal ">Day of birth:</div>
                <div className="font-bold text-[#D94F63]">Needs Completion</div>
              </li>
            );
          }
          if (
            birth_key === 'birth_year' &&
            !human[key]?.birth_year &&
            showMissingFields
          ) {
            details.push(
              <li key={birth_key} className="flex justify-between">
                <div className="font-normal text-[#D94F63]">Year of birth:</div>
                <div className="font-bold">Needs Completion</div>
              </li>
            );
          }
          if (
            birth_key === 'birth_place' &&
            !human[key]?.birth_place &&
            showMissingFields
          ) {
            details.push(
              <li key={birth_key} className="flex justify-between">
                <div className="font-normal">Place of birth:</div>
                <div className="font-bold  text-[#D94F63]">
                  Needs Completion
                </div>
              </li>
            );
          }

          if (
            birth_key === 'birth_month' &&
            !human[key]?.birth_month &&
            showMissingFields
          ) {
            details.push(
              <li key={birth_key} className="flex justify-between">
                <div className="font-normal ">Month of birth:</div>
                <div className="font-bold text-[#D94F63]">Needs Completion</div>
              </li>
            );
          }
          if (
            birth_key === 'birth_country' &&
            !human[key]?.birth_country &&
            showMissingFields
          ) {
            details.push(
              <li key={birth_key} className="flex justify-between">
                <div className="font-normal text-[#D94F63]">
                  Country of birth:
                </div>
                <div className="font-bold">Needs Completion</div>
              </li>
            );
          }
          if (
            birth_key === 'birth_city' &&
            !human[key]?.birth_city &&
            showMissingFields
          ) {
            details.push(
              <li key={birth_key} className="flex justify-between">
                <div className="font-normal text-[#D94F63]">City of birth:</div>
                <div className="font-bold">Needs Completion</div>
              </li>
            );
          }
        });
        details.push(
          <li
            key={
              key +
              (human[key]?.birth_year ||
                human[key]?.birth_month ||
                human[key]?.birth_day)
            }
            className="flex justify-between"
          >
            <div className="font-normal">
              {' '}
              {!human[key]?.birth_month || !human[key]?.birth_day
                ? 'Year of birth:'
                : 'Date of birth:'}
            </div>
            <div className="font-bold">{`${
              human[key]?.birth_month
                ? moment()
                    .month(human[key]?.birth_month - 1)
                    .format('MMMM')
                : ''
            } ${human[key]?.birth_day ? human[key]?.birth_day + ',' : ''}
          ${
            human[key]?.is_inferred
              ? `Around ${human[key]?.birth_year}`
              : human[key]?.birth_year
          }`}</div>
          </li>
        );
      }
      if (
        key === 'birth' &&
        !human['death']?.death_year &&
        calculateAge(human[key]) < 100
      ) {
        details.push(
          <li key={key} className="flex justify-between">
            <div className="font-normal">Age:</div>
            <div className="font-bold">{calculateAge(human[key])}</div>
          </li>
        );
      }
      if (key === 'birth' && human[key]?.birth_country) {
        details.push(
          <li
            key={key + human[key]?.birth_country}
            className="flex justify-between"
          >
            <div className="font-normal">Country of Birth:</div>
            <div className="text-right font-bold">
              {human[key]?.birth_country}
            </div>
          </li>
        );
      }
      if (
        key === 'birth' &&
        (human[key]?.birth_place || human[key]?.birth_city)
      ) {
        details.push(
          <li
            key={key + (human[key]?.birth_place || human[key]?.birth_city)}
            className="flex justify-between"
          >
            <div className="font-normal">Place of Birth:</div>
            <div className="text-right font-bold">
              {human[key]?.birth_city ? `${human[key]?.birth_city + ', '}` : ''}
              {human[key]?.birth_place ? `${human[key]?.birth_place}` : ''}
            </div>
          </li>
        );
      }

      if (key === 'death') {
        if (
          human[key]?.death_year ||
          human[key]?.death_month ||
          human[key]?.death_day
        )
          details.push(
            <li
              key={
                key +
                (human[key]?.death_year ||
                  human[key]?.death_month ||
                  human[key]?.death_day)
              }
              className="flex justify-between"
            >
              <div className="font-normal">
                {!human[key]?.death_month || !human[key]?.death_day
                  ? 'Year of death:'
                  : 'Date of death:'}
              </div>
              <div className="font-bold">{`${
                human[key]?.death_month
                  ? moment()
                      .month(human[key]?.death_month - 1)
                      .format('MMMM')
                  : ''
              } ${human[key]?.death_day ? human[key]?.death_day + ',' : ''}
          ${human[key]?.death_year}`}</div>
            </li>
          );
        if (
          human[key]?.death_place ||
          human[key]?.death_city ||
          human[key]?.death_country
        ) {
          details.push(
            <li
              key={key + (human[key]?.death_place || human[key]?.death_city)}
              className="flex justify-between gap-4"
            >
              <div className="font-normal">Place of Death:</div>
              <div className="text-right font-bold">
                {human[key]?.death_city
                  ? `${human[key]?.death_city + ', '}`
                  : ''}
                {human[key]?.death_place
                  ? `${human[key]?.death_place}${
                      human[key]?.death_country ? ', ' : ''
                    }`
                  : ''}
                {human[key]?.death_country
                  ? `${human[key]?.death_country}`
                  : ''}
              </div>
            </li>
          );
        }
      }
      if (key === 'sex' && human['sex'] !== 'unknown') {
        details.push(
          <li key={key + index} className="flex justify-between">
            <div className="font-normal">Sex at Birth:</div>
            <div className="font-bold">{capitalizeString(human[key])}</div>
          </li>
        );
      }
    });

    return (
      <ul
        className={`flex flex-col gap-3 text-sm ${className}`}
        ref={ref as Ref<HTMLUListElement>}
      >
        {details}
      </ul>
    );
  }
);

export default HumanDetailsContainer;
