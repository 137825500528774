import debounce from 'lodash/debounce';
import { useState, useEffect } from 'react';

export function useTypingDetector(
  inputElement: HTMLInputElement | null,
  ms = 1000
) {
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (!inputElement) {
      setIsTyping(true);
      setTimeout(() => {
        setIsTyping(false);
      }, ms);
      return;
    }

    const startTyping = (e: KeyboardEvent) => {
      if (
        e?.code?.includes('Digit') ||
        e?.code === 'Backspace' ||
        e?.code === 'Slash'
      ) {
        setIsTyping(true);
      }
    };
    const stopTyping = debounce(() => {
      setIsTyping(false);
    }, ms);

    inputElement.addEventListener('keydown', startTyping);
    inputElement.addEventListener('keyup', stopTyping);
    inputElement.addEventListener('focusout', stopTyping);

    return () => {
      inputElement.removeEventListener('keydown', startTyping);
      inputElement.removeEventListener('keyup', stopTyping);
      inputElement.addEventListener('focusout', stopTyping);
    };
  }, [inputElement]);

  return isTyping;
}
