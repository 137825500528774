import { useRouter } from 'next/router';
import type { DataWorldChartTypes } from 'src/types/types';
import { Paragraph } from '../paragraph/paragraph';
import { SecondaryButton } from '../secondary-button/secondary-button';

interface RecordStillProcessingTypes {
  countries: DataWorldChartTypes[];
  linkedPage: boolean;
}

export const RecordStillProcessing = ({
  countries,
  linkedPage = false,
}: RecordStillProcessingTypes) => {
  const router = useRouter();
  const goBack = async () => {
    await router?.back();
    if (
      router?.pathname === '/create-human' &&
      router?.query?.status === 'success'
    ) {
      router?.push('/create-human');
    } else if (
      router?.pathname !== '/create-human' &&
      router?.query?.status === 'success'
    ) {
      const countryIso2 = countries.find(
        (country) =>
          country?.name?.toLowerCase() ===
          (router?.query?.country as string)?.toLowerCase()
      )?.iso_2;

      await router?.push(
        `/${router?.query?.uuid}-${countryIso2?.toUpperCase()}`
      );
    }
  };
  return (
    <div className="flex flex-col justify-center items-center px-[30px] mx-auto mt-[30px] w-auto h-[150px] bg-white shadow-sm sm:w-[500px]">
      {linkedPage ? (
        <Paragraph fontSize={16} className="text-center">
          Record still processing...
        </Paragraph>
      ) : (
        <Paragraph fontSize={16} className="text-center">
          Record is still being processed. It will be editable in a few minutes
        </Paragraph>
      )}
      <SecondaryButton
        textClassName="text-[14px] leading-[17px] font-[600] tracking-[0.04em] rounded-[4px]"
        extraButtonClassName="mt-[20px] w-[100px] h-[30px]"
        text="Go back"
        onClick={goBack}
      />
    </div>
  );
};
