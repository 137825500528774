import InfoOutlinedIcon from 'src/assets/InfoOutlinedIcon';

export default function TailwindAlert({
  message,
  className,
}: {
  message: string;
  className?: string;
}) {
  return (
    <div className={`rounded-md bg-gray-100 p-4 mt-2 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InfoOutlinedIcon className="h-4 w-4" aria-hidden="true" />
        </div>
        <div className="ml-1 flex-1 md:flex md:justify-between">
          <p className="text-xs text-gray-700">{message}</p>
        </div>
      </div>
    </div>
  );
}
