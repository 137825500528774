import moment from 'moment';
import Link from 'next/link';
import EditInfoIcon from 'src/assets/EditInfoIcon';
import type { HumanRecord } from 'src/types/human';
import { allCountries } from 'src/utils/allCountries';
import { capitalizeString } from 'src/utils/string';

interface TableOfSimilarRecordsProps {
  similarRecords: HumanRecord[];
  countryFromProps: string;
}

export default function TableSimilarRecords({
  similarRecords,
  countryFromProps,
}: TableOfSimilarRecordsProps) {
  const getCountryIso3 = (country: string | undefined) => {
    return allCountries.find(
      (countryFromFE) =>
        countryFromFE.name?.toLocaleLowerCase() === country?.toLocaleLowerCase()
    )?.['alpha-3'];
  };
  const getCountryIso2 = (country: string | undefined) => {
    return allCountries.find(
      (countryFromFE) =>
        countryFromFE.name?.toLocaleLowerCase() === country?.toLocaleLowerCase()
    )?.['alpha-2'];
  };

  return (
    <div className="my-2 rounded-lg border border-gray-200">
      <table className="w-full border-collapse rounded-lg text-sm font-[500]">
        <thead className="rounded-lg text-left text-sm">
          <tr className="border-b border-gray-100 py-6">
            <th className="p-4">Name</th>
            <th className="p-4">Birth Date</th>
            <th className="p-4">Birth Place</th>
            <th className="p-4"></th>{' '}
          </tr>
        </thead>
        <tbody>
          {similarRecords?.slice(0, 15)?.map((record: HumanRecord) => {
            return (
              <tr
                key={record.id}
                className="cursor-pointer border-b border-gray-200 py-6 text-sm font-normal hover:bg-gray-200"
              >
                {' '}
                <td className="p-4 ">
                  {`${capitalizeString(record.name.first_name1)}
                    ${capitalizeString(record.name.last_name1)}`}
                </td>
                <td className="p-4 ">
                  {record?.birth?.year ? record?.birth?.year : ''}
                  <span className="text-gray-400">{`${
                    record.birth?.month
                      ? ', ' +
                        moment()
                          .month(record.birth?.month - 1)
                          .format('MMM')
                      : ''
                  } ${record.birth?.day ? record.birth?.day : ''}`}</span>
                </td>
                <td className="p-4 ">
                  {record.birth.region ? (
                    <p>
                      <span className="text-gray-400">
                        {record.birth.region}
                      </span>
                      , {getCountryIso3(record?.birth?.country)}
                    </p>
                  ) : getCountryIso3(record?.birth?.country) ? (
                    getCountryIso3(record?.birth?.country)
                  ) : (
                    ''
                  )}
                </td>
                <td className="p-4 ">
                  <Link
                    href={`/${record.id}-${getCountryIso2(countryFromProps)}`}
                  >
                    <EditInfoIcon />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
