import isEmpty from 'lodash/isEmpty';
import type { NextRouter } from 'next/router';
import { getHumanHistory } from 'src/actions/editActions';
import { searchByFilters } from 'src/actions/searchActions';
import type { HumanPersonTypes } from 'src/types/edit-history-types';
import type { DataWorldChartTypes } from 'src/types/types';
import { getAllChangesFromPersonHistory } from './getAllChangesFromPersonHistory';

export const createHumanArray = async (
  id: string,
  countryIso3: string,
  router: NextRouter | null = null,
  countries?: DataWorldChartTypes[]
) => {
  const domainName = countries?.find((countryFromProps) => {
    return (
      countryFromProps?.iso_3?.toLowerCase() === countryIso3?.toLowerCase()
    );
  })?.api_public_domain_name as string;

  const personHistory = await getHumanHistory(id, countryIso3, `${domainName}`);

  if (!personHistory) {
    return "Couldn't get person history";
  }

  const human = getAllChangesFromPersonHistory(personHistory?.history);

  const firstName =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'first_name')
      ?.new_value?.toLowerCase() || '';

  const middleName =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'first_name2')
      ?.new_value?.toLowerCase() || '';

  const lastName =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'last_name')
      ?.new_value?.toLowerCase() || '';

  const sex =
    human?.find((person: HumanPersonTypes) => person.field_name === 'sex')
      ?.new_value === '1' ||
    human?.find((person: HumanPersonTypes) => person.field_name === 'sex')
      ?.new_value === '10001'
      ? 'male'
      : 'female' || 'both';

  const birthDay =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'birth_date')
      ?.new_value?.split('-')?.[2]
      ?.trim() !== '00'
      ? (human
          ?.find(
            (person: HumanPersonTypes) => person.field_name === 'birth_date'
          )
          ?.new_value?.split('-')?.[2] as string)
      : '' || '';

  const birthMonth =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'birth_date')
      ?.new_value?.split('-')?.[1]
      ?.trim() !== '00'
      ? (human
          ?.find(
            (person: HumanPersonTypes) => person.field_name === 'birth_date'
          )
          ?.new_value?.split('-')?.[1] as string)
      : '' || '';

  const birthYear =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'birth_date')
      ?.new_value?.split('-')?.[0]
      ?.trim() !== '00'
      ? (human
          ?.find(
            (person: HumanPersonTypes) => person.field_name === 'birth_date'
          )
          ?.new_value?.split('-')?.[0] as string)
      : '' || '';

  // Death Data
  const deathDay =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'death_date')
      ?.new_value?.split('-')?.[2]
      ?.trim() !== '00'
      ? (human
          ?.find(
            (person: HumanPersonTypes) => person.field_name === 'death_date'
          )
          ?.new_value?.split('-')?.[2] as string)
      : '' || '';

  const deathMonth =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'death_date')
      ?.new_value?.split('-')?.[1]
      ?.trim() !== '00'
      ? (human
          ?.find(
            (person: HumanPersonTypes) => person.field_name === 'death_date'
          )
          ?.new_value?.split('-')?.[1] as string)
      : '' || '';

  const deathYear =
    human
      ?.find((person: HumanPersonTypes) => person.field_name === 'death_date')
      ?.new_value?.split('-')?.[0]
      ?.trim() !== '00'
      ? (human
          ?.find(
            (person: HumanPersonTypes) => person.field_name === 'death_date'
          )
          ?.new_value?.split('-')?.[0] as string)
      : '' || '';

  const searchParams = {
    first_name2: '',
    birth_day: '',
    birth_month: '',
    birth_year: '',
    birth_country: '',
    birth_region: '',
    birth_city: '',
    death_day: '',
    death_month: '',
    death_year: '',
    death_country: '',
    death_region: '',
    death_city: '',
    sex: sex || '',
    alive: 'both',
  };

  for (const value in searchParams) {
    if (middleName?.length > 0) {
      searchParams.first_name2 = middleName?.toString();
    }

    if (birthDay?.length > 0) {
      searchParams.birth_day = birthDay?.toString();
    }

    if (birthMonth?.length > 0) {
      searchParams.birth_month = birthMonth?.toString();
    }

    if (birthYear?.length > 0) {
      searchParams.birth_year = birthYear?.toString();
    }

    if (deathDay?.length > 0) {
      searchParams.death_day = deathDay?.toString();
    }

    if (deathMonth?.length > 0) {
      searchParams.death_month = deathMonth?.toString();
    }

    if (deathYear?.length > 0) {
      searchParams.death_year = deathYear?.toString();
    }

    if (sex) {
      searchParams.sex = sex?.toString() || 'both';
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ((searchParams[value] as string)?.length === 0 || !searchParams[value]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete searchParams[value];
    }
  }

  const humansFromSearchAPI = await searchByFilters(
    firstName,
    lastName,
    countryIso3,
    '1',
    '100',
    new URLSearchParams(searchParams)?.toString(),
    domainName
  );

  const humanFromSearchAPI =
    humansFromSearchAPI?.data?.length > 0
      ? humansFromSearchAPI?.data?.find(
          (humanFromSearch: { id: string }) => humanFromSearch?.id === id
        )
      : {};

  const dataToConcat = () => {
    const birthObject = !isEmpty(humanFromSearchAPI)
      ? [
          {
            field_name: 'birth_country',
            new_value: humanFromSearchAPI?.birth?.country,
          },
          {
            field_name: 'birth_city',
            new_value: humanFromSearchAPI?.birth?.city,
          },
          {
            field_name: 'birth_region',
            new_value: humanFromSearchAPI?.birth?.region,
          },
        ]
      : [];
    const deathObject = !isEmpty(humanFromSearchAPI)
      ? [
          {
            field_name: 'death_country',
            new_value: humanFromSearchAPI?.death?.country,
          },
          {
            field_name: 'death_city',
            new_value: humanFromSearchAPI?.death?.city,
          },
          {
            field_name: 'death_region',
            new_value: humanFromSearchAPI?.death?.region,
          },
        ]
      : [];
    const completionIndex = !isEmpty(humanFromSearchAPI)
      ? [
          {
            field_name: 'completion_index',
            new_value: humanFromSearchAPI?.completion_index,
          },
        ]
      : [];
    if (
      !isEmpty(birthObject) &&
      !isEmpty(deathObject) &&
      !isEmpty(completionIndex)
    ) {
      return birthObject
        ?.concat(deathObject)
        ?.concat(completionIndex)
        .concat([
          {
            field_name: 'editable',
            new_value: humanFromSearchAPI?.editable,
          },
        ]);
    }

    if (
      !isEmpty(birthObject) &&
      isEmpty(deathObject) &&
      !isEmpty(completionIndex)
    ) {
      return birthObject?.concat(completionIndex).concat([
        {
          field_name: 'editable',
          new_value: humanFromSearchAPI?.editable,
        },
      ]);
    }

    if (
      isEmpty(birthObject) &&
      !isEmpty(deathObject) &&
      !isEmpty(completionIndex)
    ) {
      return deathObject?.concat(completionIndex).concat([
        {
          field_name: 'editable',
          new_value: humanFromSearchAPI?.editable,
        },
      ]);
    }

    if (
      !isEmpty(birthObject) &&
      !isEmpty(deathObject) &&
      isEmpty(completionIndex)
    ) {
      return birthObject?.concat(deathObject).concat([
        {
          field_name: 'editable',
          new_value: humanFromSearchAPI?.editable,
        },
      ]);
    }

    if (
      !isEmpty(birthObject) &&
      isEmpty(deathObject) &&
      isEmpty(completionIndex)
    ) {
      return birthObject.concat([
        {
          field_name: 'editable',
          new_value: humanFromSearchAPI?.editable,
        },
      ]);
    }

    if (
      isEmpty(birthObject) &&
      !isEmpty(deathObject) &&
      isEmpty(completionIndex)
    ) {
      return deathObject.concat([
        {
          field_name: 'editable',
          new_value: humanFromSearchAPI?.editable,
        },
      ]);
    }

    if (
      isEmpty(birthObject) &&
      isEmpty(deathObject) &&
      !isEmpty(completionIndex)
    ) {
      return completionIndex.concat([
        {
          field_name: 'editable',
          new_value: humanFromSearchAPI?.editable,
        },
      ]);
    }

    return [];
  };

  if (router) {
    const humanArray =
      router?.pathname === '/linked'
        ? dataToConcat()?.length > 0
          ? human?.concat(dataToConcat())
          : human
        : null;

    return humanArray;
  } else {
    const humanArray = humanFromSearchAPI?.editable
      ? dataToConcat()?.length > 0
        ? human?.concat(dataToConcat())
        : human
      : null;

    return humanArray;
  }
};
