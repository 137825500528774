import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useSpring } from 'react-spring';
import { getLocation, getLocationPath } from 'src/actions/countryActions';
import QuestionMarkIconCircle from 'src/assets/QuestionMarkIconCircle';
import DayMonthYearFilter, {
  monthOptions,
} from 'src/filters/day-month-year-filter';
import type { RootState } from 'src/store';

import type {
  ListRegionStatesTypes,
  LocationsTypes,
  PathTypes,
} from 'src/types/edit-create-types';
import type { DataWorldChartTypes } from 'src/types/types';
import { isBirthDateBeforeDeathDate } from 'src/utils/isBirthDateBeforeDeathDate';
import moveCountryToFirstPlace from 'src/utils/moveRefCountryToFirstPlace';
import TailwindAlert from '../alert/tailwind-alert';
import ReactTooltip from '../react-tooltip/ReactTooltip';
import TailwindSelect from '../select/select';

interface DeathDetailsProps {
  countries: DataWorldChartTypes[] | LocationsTypes[];
  handleDeathDay: (value: ChangeEvent<HTMLInputElement>) => void;
  handleDeathMonth: (value: string) => void;
  handleDeathYear: (value: ChangeEvent<HTMLInputElement>) => void;
  handleDeathCity: (value: string) => void;
  handleDeathRegion: (value: string) => void;
  handleDeathCountry: (value: string) => void;
  continueAndReview: boolean;
  deathDate?: {
    day?: string;
    month?: string;
    year?: string;
    approximateYear?: string;
  };
  deathCity?: string;
  deathRegion?: string;
  deathCountry?: string;
  countryRef?: string;
  birthDate?: {
    day?: string;
    month?: string;
    year?: string;
  };
}

export default function DeathDetails({
  countries,
  handleDeathDay,
  handleDeathMonth,
  handleDeathYear,
  handleDeathCity,
  handleDeathRegion,
  handleDeathCountry,
  continueAndReview,
  deathCity,
  deathRegion,
  deathCountry,
  countryRef,
  deathDate = {
    day: '',
    month: '',
    year: '',
    approximateYear: '',
  },
  birthDate,
}: DeathDetailsProps) {
  const [deathCountryState, setDeathCountryState] = useState('');
  const [deathRegionState, setDeathRegionState] = useState('');
  const [deathCityState, setDeathCityState] = useState('');
  const [, setTypeOfSubdivision] = useState('');
  const [showRegionState, setShowRegionState] = useState(false);
  const [searchTermDeathCity, setSearchTermDeathCity] = useState('');
  const [isLoadingAllRegionsDeath, setIsLoadingAllRegionsDeath] =
    useState(true);
  const [deathStatePath, setDeathStatePath] = useState('');
  const [listOfRegionsStates, setListOfRegionsState] = useState<
    ListRegionStatesTypes[] | undefined
  >(undefined);
  const [pathsForLocation, setPathsForLocation] = useState<PathTypes[] | []>(
    []
  );
  const { isLoggedIn, userId } = useSelector((state: RootState) => state.user);

  const [isLoadingAllCitiesDeath, setIsLoadingAllCities] = useState(false);
  const styleForDisappearingDateOfBirh = useSpring({
    opacity: 1,
    display: 'flex',
    config: { tension: 900, friction: 50 },
  });
  const [dateOfDeath, setDateOfDeath] = useState(deathDate);
  const handleCountry = (country: string) => {
    if (country != deathCountry) {
      setDeathRegionState('');
      handleDeathRegion('');
      setDeathCityState('');
      handleDeathCity('');
    }
    setDeathCountryState(country);
    handleDeathCountry(country);
  };

  useEffect(() => {
    if (deathCountry && deathCountry?.length > 0) {
      const newFoundCountry = (countries as LocationsTypes[])?.find(
        (countryWithRegion: LocationsTypes) =>
          countryWithRegion?.place?.toLowerCase() ===
          deathCountry?.toLowerCase()
      );

      const foundCountryDomainName = (countries as DataWorldChartTypes[])?.find(
        (country: DataWorldChartTypes) => country?.iso_3 === newFoundCountry?.id
      )?.api_public_domain_name as string;

      if ((newFoundCountry?.region_label as string)?.length > 0) {
        setTypeOfSubdivision(newFoundCountry?.region_label as string);
      }

      if (newFoundCountry?.id) {
        getLocation(newFoundCountry?.id, foundCountryDomainName)
          .then((res) => {
            setShowRegionState(true);
            setIsLoadingAllRegionsDeath(false);
            setListOfRegionsState(res);
          })
          .catch(() => {
            setIsLoadingAllRegionsDeath(false);
          });
      } else {
        setIsLoadingAllRegionsDeath(false);
      }
    } else {
      setIsLoadingAllRegionsDeath(false);
      setListOfRegionsState([]);
    }
  }, [deathCountry, deathCountryState, countries]);

  useEffect(() => {
    const regionPath =
      (listOfRegionsStates &&
        (listOfRegionsStates?.find(
          (region) =>
            region?.place?.toLowerCase() === deathRegionState?.toLowerCase()
        )?.path as string)) ||
      '';

    if (
      listOfRegionsStates &&
      listOfRegionsStates?.length > 0 &&
      regionPath?.length > 0
    ) {
      if (deathCountryState?.length > 0) {
        const newFoundCountry = (countries as LocationsTypes[]).find(
          (countryWithRegion: LocationsTypes) =>
            countryWithRegion?.place?.toLowerCase() ===
            deathCountry?.toLowerCase()
        );

        const foundCountryDomainName = (
          countries as DataWorldChartTypes[]
        )?.find(
          (country: DataWorldChartTypes) =>
            country?.iso_3 === newFoundCountry?.id
        )?.api_public_domain_name as string;

        setDeathStatePath(regionPath);
        getLocationPath(
          newFoundCountry?.id as string,
          regionPath,
          foundCountryDomainName
        )
          .then((res) => {
            if (res) {
              setPathsForLocation(res);
              setIsLoadingAllCities(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setPathsForLocation([]);
            setIsLoadingAllCities(false);
          });
      } else {
        setPathsForLocation([]);
      }
    } else {
      if (!isLoadingAllRegionsDeath) {
        setIsLoadingAllCities(false);
        setPathsForLocation([]);
      }
    }
  }, [
    deathCountry,
    listOfRegionsStates,
    deathRegionState,
    deathCountryState,
    deathRegionState,
  ]);

  const handleDay = (e: ChangeEvent<HTMLInputElement>) => {
    setDateOfDeath((prev) => ({ ...prev, day: e.target.value }));
    handleDeathDay(e);
  };

  const handleYear = (e: ChangeEvent<HTMLInputElement>) => {
    setDateOfDeath((prev) => ({ ...prev, year: e.target.value }));
    handleDeathYear(e);
  };

  const handleMonth = (month: string) => {
    if (month === 'Select') {
      setDateOfDeath((prev) => ({ ...prev, month: '', day: '' }));
    } else {
      setDateOfDeath((prev) => ({ ...prev, month: month }));
    }

    handleDeathMonth(
      monthOptions.find(
        (monthOption: { label: string; value: string }) =>
          monthOption.label === month
      )?.value || ''
    );
  };

  const handleApproximateYear = (e: ChangeEvent<HTMLInputElement>) => {
    setDateOfDeath((prev) => ({ ...prev, approximateYear: e.target.value }));
  };

  const handleDeathState = (state: string) => {
    if (state != deathRegion) {
      setDeathCityState('');
      handleDeathCity('');
    }
    setDeathRegionState(state);
    handleDeathRegion(state);
  };

  const onSelectPath = (selectedRegionState: string) => {
    setDeathCityState(selectedRegionState);
    setSearchTermDeathCity(selectedRegionState);
    handleDeathCity(selectedRegionState);
  };

  useEffect(() => {
    if (deathCountry && deathCountry?.length > 0) {
      const foundCountry = (countries as LocationsTypes[]).find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          deathCountry?.toLowerCase()
      );

      const foundCountryDomainName = (countries as DataWorldChartTypes[])?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if (foundCountry?.id) {
        getLocation(foundCountry?.id, foundCountryDomainName).then((res) => {
          if (res) {
            setListOfRegionsState(res);
          }
        });
      }
    } else {
      setListOfRegionsState([]);
    }
  }, [deathCountry]);

  useEffect(() => {
    const regionPath =
      (listOfRegionsStates?.find(
        (region) => region?.place?.toLowerCase() === deathRegion?.toLowerCase()
      )?.path as string) || '';
    if (
      listOfRegionsStates &&
      listOfRegionsStates?.length > 0 &&
      regionPath?.length > 0
    ) {
      const foundCountry = (countries as LocationsTypes[]).find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          deathCountry?.toLowerCase()
      );

      const foundCountryDomainName = (countries as DataWorldChartTypes[])?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if (foundCountry?.id && regionPath) {
        getLocationPath(
          foundCountry?.id as string,
          regionPath,
          foundCountryDomainName
        )
          .then((res) => {
            if (res) {
              setPathsForLocation(res);
            }
          })
          .catch((err) => {
            console.log(err);

            setPathsForLocation([]);
          });
      }
    }
  }, [deathCountry, listOfRegionsStates, deathRegion]);

  return (
    <div className="w-full desktop:w-[400px] flex gap-2 flex-col">
      <div className="flex items-center">
        <h4 className="font-semibold">Death Details</h4>{' '}
        <QuestionMarkIconCircle
          className="ml-2 w-5 h-5 flex items-center"
          id="death-details"
          data-for="death-details"
          data-tip={
            'Enter the year first, then the month and the day if you know them'
          }
        />
        <ReactTooltip
          className="tooltip-opacity"
          id="death-details"
          type={'info'}
          multiline={true}
          effect={'solid'}
          place="top"
          backgroundColor={'#ECECEC'}
          textColor={'#454545'}
        />
      </div>
      <DayMonthYearFilter
        styles={styleForDisappearingDateOfBirh}
        disabled={!isLoggedIn || continueAndReview}
        containerClassName="flex items-center gap-2 w-full"
        onChangeDay={handleDay}
        onChangeApproxYear={handleApproximateYear}
        onChangeMonth={handleMonth}
        onChangeYear={handleYear}
        date={deathDate}
        monthDropdown={true}
        showApproxYears={false}
      />{' '}
      {!isBirthDateBeforeDeathDate(birthDate, deathDate) && (
        <div className="text-red-500 text-xs mt-1">
          {'The death date must be greater than the birth date.'}
        </div>
      )}
      <TailwindSelect
        label="Country of death"
        disabled={!isLoggedIn || continueAndReview}
        className="!text-slateBlack !font-normal !m-0"
        options={(
          moveCountryToFirstPlace(
            countries as LocationsTypes[],
            countryRef
          ) as LocationsTypes[]
        )?.map((countryObj: LocationsTypes) => countryObj.place)}
        placeholder={'Select country'}
        value={deathCountry}
        onChange={handleCountry}
      />
      {(listOfRegionsStates && listOfRegionsStates?.length > 0) ||
      deathRegion ? (
        <TailwindSelect
          label="State of death"
          className="!text-slateBlack !font-normal !m-0"
          options={listOfRegionsStates?.map(
            (countryObj: ListRegionStatesTypes) => countryObj.place
          )}
          disabled={
            listOfRegionsStates?.length === 0 || continueAndReview
              ? true
              : false
          }
          placeholder={'Select state'}
          value={deathRegion}
          onChange={handleDeathState}
        />
      ) : (
        deathCountry && (
          <TailwindAlert
            className="w-full desktop:w-[400px]"
            message="Currently we are collecting data at the national level only."
          />
        )
      )}
      {((pathsForLocation && pathsForLocation?.length > 0) || deathCity) && (
        <TailwindSelect
          label="City of death"
          className="!text-slateBlack !font-normal !m-0"
          disabled={
            pathsForLocation?.length === 0 || continueAndReview ? true : false
          }
          options={pathsForLocation?.map((location) => location.place) || []}
          placeholder={'Select city'}
          value={deathCity}
          onChange={onSelectPath}
        />
      )}
    </div>
  );
}
