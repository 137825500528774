import type { IconProps } from '../types/icons';

export default function SucceedIcon({
  width = 38,
  height = 38,
  fill = '#34A28A',
  className = '',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        d="M19 0.666748C8.91663 0.666748 0.666626 8.91675 0.666626 19.0001C0.666626 29.0834 8.91663 37.3334 19 37.3334C29.0833 37.3334 37.3333 29.0834 37.3333 19.0001C37.3333 8.91675 29.0833 0.666748 19 0.666748ZM19 33.6667C10.915 33.6667 4.33329 27.0851 4.33329 19.0001C4.33329 10.9151 10.915 4.33341 19 4.33341C27.085 4.33341 33.6666 10.9151 33.6666 19.0001C33.6666 27.0851 27.085 33.6667 19 33.6667ZM27.415 10.8967L15.3333 22.9784L10.585 18.2484L7.99996 20.8334L15.3333 28.1667L30 13.5001L27.415 10.8967Z"
        fill={fill}
      />
    </svg>
  );
}
